<template>
  <div id="recallReport">
    <el-dialog
      :title="recallReportFormTitle"
      width="1200px"
      :visible.sync="recallReportDialogVisible"
      :close-on-click-modal="false"
      @close="recallReportDialogClose"
    >
      <el-form
        ref="recallReportFormRef"
        :model="recallReportForm"
        :rules="recallReportFormRules"
        label-position="right"
        label-width="150px"
      >
        <el-row>
          <el-col :span="8">
            <el-form-item label="产品名称" prop="productName">
              <el-input v-model="recallReportForm.productName" placeholder="请输入产品名称" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="注册证号码" prop="registrationNum">
              <el-input v-model="recallReportForm.registrationNum" placeholder="请输入注册证号码" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="生产企业" prop="manufacturer">
              <el-input v-model="recallReportForm.manufacturer" placeholder="请输入生产企业" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="中国境内负责单位、负责人及联系方式" prop="responsible">
              <el-input
                v-model="recallReportForm.responsible"
                placeholder="请输入中国境内负责单位、负责人及联系方式"
                type="textarea"
                :rows="4"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="召回工作联系人和联系方式" prop="contact">
              <el-input
                v-model="recallReportForm.contact"
                placeholder="请输入召回工作联系人和联系方式"
                type="textarea"
                :rows="4"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="产品的适用范围" prop="applicationScope">
              <el-input
                v-model="recallReportForm.applicationScope"
                placeholder="请输入产品的适用范围"
                type="textarea"
                :rows="4"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="涉及地区和国家" prop="areaInvolved" label-width="260px">
              <el-input v-model="recallReportForm.areaInvolved" placeholder="请输入涉及地区和国家" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="涉及产品型号、规格" prop="modelInvolved" label-width="260px">
              <el-input v-model="recallReportForm.modelInvolved" placeholder="请输入涉及产品型号、规格" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="涉及产品生产（或进口中国）数量" prop="productionQuantityInvolved" label-width="260px">
              <el-input v-model="recallReportForm.productionQuantityInvolved" placeholder="请输入涉及产品生产（或进口中国）数量" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="涉及产品在中国的销售数量" prop="saleQuantityInvolved" label-width="260px">
              <el-input v-model="recallReportForm.saleQuantityInvolved" placeholder="请输入涉及产品在中国的销售数量" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="识别信息" prop="identificationInformation">
              <el-input
                v-model="recallReportForm.identificationInformation"
                placeholder="请输入识别信息"
                type="textarea"
                :rows="3"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="召回原因简述" prop="recallReason">
              <el-input
                v-model="recallReportForm.recallReason"
                placeholder="请输入召回原因简述"
                type="textarea"
                :rows="3"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="纠正行动简述" prop="correctiveAction">
              <el-input
                v-model="recallReportForm.correctiveAction"
                placeholder="请输入纠正行动简述"
                type="textarea"
                :rows="3"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="报告单位" prop="reportingUnit">
              <el-input v-model="recallReportForm.reportingUnit" placeholder="请输入报告单位" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="负责人" prop="personInCharge">
              <el-input v-model="recallReportForm.personInCharge" placeholder="请输入负责人" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="报告人" prop="reporter">
              <el-input v-model="recallReportForm.reporter" placeholder="请输入报告人" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="报告日期" prop="reportDate">
              <el-date-picker v-model="recallReportForm.reportDate" placeholder="请选择报告日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="recallReportDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="recallReportFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="产品名称">
        <el-input v-model="searchForm.productName" placeholder="请输入产品名称" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button type="primary" icon="el-icon-plus" @click="handleAdd">
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="recallReportPage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :height="getTableHeight()"
    >
      <el-table-column prop="productName" label="产品名称" />
      <el-table-column prop="registrationNum" label="注册证号码" />
      <el-table-column prop="manufacturer" label="生产企业" />
      <el-table-column prop="responsible" label="中国境内负责单位、负责人及联系方式" />
      <el-table-column prop="contact" label="召回工作联系人和联系方式" />
      <el-table-column prop="applicationScope" label="产品的适用范围" />
      <el-table-column prop="areaInvolved" label="涉及地区和国家" />
      <el-table-column prop="modelInvolved" label="涉及产品型号、规格" />
      <el-table-column prop="productionQuantityInvolved" label="涉及产品生产（或进口中国）数量" />
      <el-table-column prop="saleQuantityInvolved" label="涉及产品在中国的销售数量" />
      <el-table-column prop="identificationInformation" label="识别信息" />
      <el-table-column prop="recallReason" label="召回原因简述" />
      <el-table-column prop="correctiveAction" label="纠正行动简述" />
      <el-table-column prop="reportingUnit" label="报告单位" />
      <el-table-column prop="reporter" label="报告人" />
      <el-table-column prop="personInCharge" label="负责人" />
      <el-table-column label="报告日期">
        <template slot-scope="scope">
          <span v-if="scope.row.reportDate">{{ scope.row.reportDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        align="center"
        width="200"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="recallReportPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import { addRecallReport, deleteRecallReport, updateRecallReport, selectRecallReportInfo, selectRecallReportList } from '@/api/quality/recallReport'

export default {
  data () {
    return {
      recallReportDialogVisible: false,
      recallReportFormTitle: '',
      recallReportForm: {
        id: '',
        productName: '',
        registrationNum: '',
        manufacturer: '',
        responsible: '',
        contact: '',
        applicationScope: '',
        areaInvolved: '',
        modelInvolved: '',
        productionQuantityInvolved: '',
        saleQuantityInvolved: '',
        identificationInformation: '',
        recallReason: '',
        correctiveAction: '',
        reportingUnit: '',
        reporter: '',
        personInCharge: '',
        reportDate: ''
      },
      recallReportFormRules: {
        productName: [{ required: true, message: '产品名称不能为空', trigger: ['blur', 'change']}]
      },
      recallReportPage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        productName: ''
      }
    }
  },
  created () {
    selectRecallReportList(this.searchForm).then(res => {
      this.recallReportPage = res
    })
  },
  methods: {
    recallReportDialogClose () {
      this.$refs.recallReportFormRef.resetFields()
    },
    recallReportFormSubmit () {
      if (this.recallReportFormTitle === '医疗器械召回事件报告详情') {
        this.recallReportDialogVisible = false
        return
      }
      this.$refs.recallReportFormRef.validate(async valid => {
        if (valid) {
          if (this.recallReportFormTitle === '新增医疗器械召回事件报告') {
            await addRecallReport(this.recallReportForm)
          } else if (this.recallReportFormTitle === '修改医疗器械召回事件报告') {
            await updateRecallReport(this.recallReportForm)
          }
          this.recallReportPage = await selectRecallReportList(this.searchForm)
          this.recallReportDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.recallReportFormTitle = '新增医疗器械召回事件报告'
      this.recallReportDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteRecallReport(row.id)
        if (this.recallReportPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.recallReportPage = await selectRecallReportList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.recallReportFormTitle = '修改医疗器械召回事件报告'
      this.recallReportDialogVisible = true
      this.selectRecallReportInfoById(row.id)
    },
    handleInfo (index, row) {
      this.recallReportFormTitle = '医疗器械召回事件报告详情'
      this.recallReportDialogVisible = true
      this.selectRecallReportInfoById(row.id)
    },
    selectRecallReportInfoById (id) {
      selectRecallReportInfo(id).then(res => {
        this.recallReportForm.id = res.id
        this.recallReportForm.productName = res.productName
        this.recallReportForm.registrationNum = res.registrationNum
        this.recallReportForm.manufacturer = res.manufacturer
        this.recallReportForm.responsible = res.responsible
        this.recallReportForm.contact = res.contact
        this.recallReportForm.applicationScope = res.applicationScope
        this.recallReportForm.areaInvolved = res.areaInvolved
        this.recallReportForm.modelInvolved = res.modelInvolved
        this.recallReportForm.productionQuantityInvolved = res.productionQuantityInvolved
        this.recallReportForm.saleQuantityInvolved = res.saleQuantityInvolved
        this.recallReportForm.identificationInformation = res.identificationInformation
        this.recallReportForm.recallReason = res.recallReason
        this.recallReportForm.correctiveAction = res.correctiveAction
        this.recallReportForm.reportingUnit = res.reportingUnit
        this.recallReportForm.reporter = res.reporter
        this.recallReportForm.personInCharge = res.personInCharge
        this.recallReportForm.reportDate = res.reportDate
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectRecallReportList(this.searchForm).then(res => {
        this.recallReportPage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectRecallReportList(this.searchForm).then(res => {
        this.recallReportPage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectRecallReportList(this.searchForm).then(res => {
        this.recallReportPage = res
      })
    }
  }
}
</script>

<style>
</style>
