import axios from '@/common/axios'
import qs from 'qs'

export function addRecallReport (data) {
  return axios({
    method: 'post',
    url: '/quality/recallReport/add',
    data: qs.stringify(data)
  })
}

export function deleteRecallReport (id) {
  return axios({
    method: 'delete',
    url: '/quality/recallReport/delete/' + id
  })
}

export function updateRecallReport (data) {
  return axios({
    method: 'put',
    url: '/quality/recallReport/update',
    data: qs.stringify(data)
  })
}

export function selectRecallReportInfo (id) {
  return axios({
    method: 'get',
    url: '/quality/recallReport/info/' + id
  })
}

export function selectRecallReportList (query) {
  return axios({
    method: 'get',
    url: '/quality/recallReport/list',
    params: query
  })
}
